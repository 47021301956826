<!--
 * @Author: 咸鱼
 * @Date: 2020-05-27 15:38:44
 * @LastEditTime: 2020-08-27 19:04:22
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/views/market/refuel/index.vue
 * @可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="container">
    <table-page dataName="refuels"
                ref="table"
                :tabList="tabList"
                :request="fetchRefuel">
      <template slot="button">
        <el-button type="primary"
                   @click="$refs.setRefuel.showModel()">新建卡券</el-button>
      </template>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="加油券ID">
      </el-table-column>
      <el-table-column prop="code"
                       show-overflow-tooltip
                       label="加油券商品编码">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="商品名称">
      </el-table-column>
      <el-table-column prop="money"
                       show-overflow-tooltip
                       label="商品面值">
      </el-table-column>
      <el-table-column prop="allot_quantity"
                       show-overflow-tooltip
                       label="当前库存">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="250px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.setQuantity.showModel(scope.row)">重置库存</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetRefuel ref="setRefuel"
               @on-success="$refs.table.fetch()" />
    <SetQuantity ref="setQuantity"
                 @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import SetRefuel from './components/SetRefuel'
import SetQuantity from './components/SetQuantity'
import TablePage from '@/components/TablePage'
import { fetchRefuel } from '@/api/market'
export default {
  name: 'Refuel',
  data () {
    return {
      fetchRefuel,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }]

    }
  },
  components: {
    TablePage,
    SetRefuel,
    SetQuantity
  },
  methods: {
    setTerrace (row) {
      this.$refs.form.showModel(row)
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
