<template>
  <el-dialog title="重置库存"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="30%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top">
      <el-form-item label="库存数量"
                    prop="quantity">
        <el-input v-model="form.quantity"
                  placeholder="请输入库存数量"></el-input>
      </el-form-item>

    </el-form>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">立即重置</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { resetRefuel } from '@/api/market'
export default {
  data () {
    return {
      selectList: [],
      dialogVisible: false,
      form: {
        refuel_id: '',
        quantity: ''
      },
      rules: {
        quantity: [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ]
      }
    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await resetRefuel(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    showModel (row) {
      this.dialogVisible = true
      this.form = {
        refuel_id: row.id,
        quantity: row.quantity
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }

  }
}
</script>

<style scoped lang="scss">
</style>
