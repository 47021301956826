<template>
  <el-dialog title="新建加油券"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="30%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top">
      <el-form-item label="类型选择"
                    prop="code">
        <el-select v-model="form.code"
                   class="width100"
                   placeholder="请选择">
          <el-option v-for="item in selectList"
                     :key="item.code"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库存数量"
                    prop="quantity">
        <el-input v-model="form.quantity"
                  placeholder="请输入库存数量"></el-input>
      </el-form-item>

    </el-form>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">立即创建</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fetchSinopec, setRefuel } from '@/api/market'
export default {
  data () {
    return {
      selectList: [],
      value: '',
      dialogVisible: false,
      form: {
        code: '',
        quantity: ''
      },
      rules: {
        quantity: [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {

  },
  mounted () {
    this.fetchSinopec()
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setRefuel(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    showModel () {
      this.dialogVisible = true
      this.form = {
        code: '',
        quantity: ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    async fetchSinopec () {
      const res = await fetchSinopec()
      if (res.meta.code === 0) {
        this.selectList = res.data.refuels
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
